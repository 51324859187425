import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

import BehaviourIcon from '../images/svgs/profiling-behaviour.svg'
import PersonalityIcon from '../images/svgs/profiling-personality.svg'
import TalentsIcon from '../images/svgs/profiling-talents.svg'

const profilingTemplate = (  { data: { strapi: { profiling } }, location } ) => {

	const metaDescription = profiling.summary ? profiling.summary.replace(/(\r\n|\n|\r)/gm, " ").substring(0, 160) : null

	const renderIcon = icon => {

		switch ( icon ) {
			case 'behaviour':
				return <BehaviourIcon className="article__icon" />
			case 'personality':
				return <PersonalityIcon className="article__icon" />
			case 'talents':
				return <TalentsIcon className="article__icon" />
			default:
				return <BehaviourIcon className="article__icon" />
		}
	}

	return (
		<>
			<SEO title={ `${ profiling.title } | Profiling ` } description={ metaDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					{ renderIcon( profiling.icon ) }
					<h1 className="article__header">{ profiling.title }</h1>
					{ profiling.buzzWord && <h2 className="article__sub-header">{ profiling.buzzWord }</h2> }

					<div className="article__body-content">
						{ profiling && profiling.content &&
							<DynamicZone components={ profiling.content } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default profilingTemplate

export const query = graphql`
	query profilingQuery($id: ID!) {
		strapi {
			profiling(id: $id) {
				id
				icon
				slug
				summary
				title
				buzzWord
				content {
					__typename
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						displayBorder
						rotateImage
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
				}
			}
		}
	}
`